import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import config from '../../../theme/config'
import {
  deafultFunc,
  defaultObject,
  defaultString,
} from '../../../theme/config'

function TextInput({
  value,
  placeholder,
  onChange,
  type,
  disabled,
  label,
  multiline,
  style,
  inputProps,
  error,
  multilineHeight,
  noBorder,
  ...rest
}) {
  const handleChange = e => {
    onChange(e.target.value)
  }

  return (
    <Box className="input-container" style={style} {...rest}>
      {label !== false && <Box className="input-label">{label}</Box>}
      {multiline ? (
        <textarea
          disabled={disabled}
          placeholder={placeholder}
          className="form-text-input"
          onInput={handleChange}
          value={value}
          style={{
            fontFamily: config.fontFamily,
            height: multilineHeight,
          }}
          {...inputProps}
        ></textarea>
      ) : (
        <input
          type={noBorder ? null : type}
          value={value}
          disabled={disabled}
          className={noBorder ? 'form-text-input-2' : 'form-text-input'}
          placeholder={placeholder}
          onInput={handleChange}
          {...inputProps}
        />
      )}
      {error !== '' && <div className="input-error">{error}</div>}
    </Box>
  )
}

export const TextInputPropTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  inputProps: PropTypes.object,
}

TextInput.propTypes = TextInputPropTypes

TextInput.defaultProps = {
  onChange: deafultFunc,
  value: defaultString,
  placeholder: defaultString,
  label: defaultString,
  style: defaultObject,
  disabled: false,
  error: defaultString,
  type: 'text',
  inputProps: defaultObject,
}

export default TextInput
